import { Locale, FacetsRetrieve, Mode } from "../../../types";

export default class FacetsBuild {
  private orderedFacets: FacetsRetrieve;
  private facetsAdmin: FacetsRetrieve;
  private ORDER_IDX = [5, 7, 4, 10, 12, 1, 2, 3];
  private ORDER_ADMIN_IDX = [5, 7, 4, 11, 12, 1, 2, 3, 8, 9];

  constructor(
    private retrievedFacets: FacetsRetrieve,
    locale: Locale,
    mode: Mode
  ) {
    const key = `other_tags.${locale}`;
    this.orderedFacets = this.orderFacets(this.retrievedFacets, locale, mode);
    this.facetsAdmin =
      mode === "admin" || mode === "b2b2c"
        ? { [key]: this.retrievedFacets[key] }
        : {};
  }

  private orderFacets(facets: FacetsRetrieve, locale: Locale, mode: Mode) {
    const order = mode === "client" ? this.ORDER_IDX : this.ORDER_ADMIN_IDX;

    return order.reduce((result, index) => {
      const key = `searchable_tags.${index}.${locale}`;

      if (Object.prototype.hasOwnProperty.call(facets, key)) {
        result[key] = { ...facets[key] };
      }
      return result;
    }, {} as FacetsRetrieve);
  }

  build() {
    return {
      ...this.orderedFacets,
      ...this.facetsAdmin,
    };
  }
}
