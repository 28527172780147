import type { HouseSlug, Locale } from 'lc-services/types'

export const hash = async (string: string) => {
  const utf8 = new TextEncoder().encode(string)
  const hashBuffer = await crypto.subtle.digest('SHA-256', utf8)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hashHex = hashArray
    .map((bytes) => bytes.toString(16).padStart(2, '0'))
    .join('')

  return hashHex
}

export const createQueryParams = (
  obj: Record<string, string | number | boolean | undefined | null>,
) => {
  return Object.entries(obj)
    .reduce((params, [key, value]) => {
      if (value !== undefined && value !== null) {
        params.append(key, value.toString())
      }
      return params
    }, new URLSearchParams())
    .toString()
}

/** Makes a (localized) string suitable for an HTML id (no space, no uppercase, no #, no _, no ', no ") */
export const hashify = (
  value?: HouseSlug | string | null,
  locale: MaybeRef<Locale> = 'en',
) =>
  !value
    ? ''
    : (typeof value === 'string' ? value : value[unref(locale)])
        .replace(/[\s_]+/g, '-')
        .replace(/[#[\]()&'"]/g, '')
        .toLowerCase()

/** Makes a localized string suitable for display (no #, no _, no -, no (), no [], no {}) */
export const beautify = (value?: HouseSlug | null) => {
  if (!value) return { en: '', fr: '' }

  const replacer = (str: string) =>
    str
      // Standardize lettering
      .toLowerCase()
      // Replace special characters #, -, _ with an empty space
      .replace(/#|-|_/g, ' ')
      // Removes all parenthesis, brackets, accolades
      .replace(/\(.+\)|\[.+\]|\{.+\}/g, '')
      // Shrink all spaces to 1 space
      .replace(/\s+/g, ' ')
      // Capitalize the first letter
      .replace(/^\w/, (char) => char.toUpperCase())
      // Capitalize all words following a space
      .replace(/\s\w/g, (char) => char.toUpperCase())
      // Removes empty spaces at beginning/end
      .trim()

  return Object.fromEntries(
    Object.entries(value).map(([locale, str]) => [locale, replacer(str)]),
  ) as HouseSlug
}
