import { FacetFiltersValues, Locale, Mode } from "../../../../types";

export const generateDefaultFacets = (locale: Locale, mode: Mode) => {
  const facetsIndexClient = [1, 2, 3, 4, 5, 7, 10, 12];
  const facetsIndexAdminAndB2b2c = [1, 2, 3, 4, 5, 7, 8, 9, 11, 12];
  return (mode === "client" ? facetsIndexClient : facetsIndexAdminAndB2b2c)
    .map((idx) => `searchable_tags.${idx}.${locale}`)
    .concat(mode === "admin" || mode === "b2b2c" ? `other_tags.${locale}` : []);
};

export const formatFacetFilters = (facetFilters: FacetFiltersValues) => {
  return Array.from(
    facetFilters,
    ([value, category]) => `${category}:${value}`
  );
};
